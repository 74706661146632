import { extendTheme } from '@chakra-ui/react';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const styles = {
  global: {
    body: {
      bg: 'linear-gradient(74deg, rgba(79,52,52,0.702) 0%, rgba(158,158,158,0.612) 100%), url("/bg.jpeg") fixed',
      bgSize: '100vw 150%, cover, cover',
      bgPosition: 'center',
      bgRepeat: 'no-repeat, repeat, no-repeat;t',
      color: '#ddd',
      bgAttachment: "fixed",
      
    },
  },
};

const colors = {
  purple: {
    100: '#B983FF',
    200: '#A872EE',
    300: '#9761DD',
    400: '#8650CC',
    800: '#080613',
    900: '#191724',
  },
};

export default extendTheme({ config, styles, colors });
