import { Box } from '@chakra-ui/react';

import { BsHouse, BsEnvelope, BsMoon } from 'react-icons/bs';

import styles from './navbar.module.css';
import PageButton from './page-button';

export default function Navbar() {
  return (
    <Box>
      <Box className={styles['content']}>
        <Box>
          <PageButton Icon={BsHouse} link={'/'}>
            Home
          </PageButton>
          <PageButton Icon={BsMoon} link={'/about'}>
            About Me
          </PageButton>
          <PageButton Icon={BsEnvelope} link={'/contact'}>
            Contact
          </PageButton>
        </Box>
      </Box>
    </Box>
  );
}
