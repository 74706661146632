import { Box, Flex } from "@chakra-ui/react";
import Navbar from '../navbar/navbar';

import styles from "./container.module.css";

export default function Container({ children }) {
  return (
    
    <Box className={styles["wrapper"]}>
      <Flex className={styles["container"]}>
        <Box className={styles["page"]}>
        <Navbar />
        {children}
        </Box>
        
      </Flex>
    </Box>
  );
}
