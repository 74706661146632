import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from '@chakra-ui/react';
import data from '../Data.json';

function AboutCard({ bio }) {
  return (
    <Text textAlign="justify" pb={5}>
      {bio}
    </Text>
  );
}
function FaqCard({ pre, res }) {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton _expanded={{ bg: 'pink.300', color: 'white' }}>
          <Box as="span" flex="1" textAlign="left">
            {pre}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} textAlign="justify">
        {res}
      </AccordionPanel>
    </AccordionItem>
  );
}

export default function About() {
  return (
    <Box px="5">
      <div>
        <div className="about">
          <div className="Bio">
            <div className="mt-5 flex items-center flex-col mx-auto justify-center">
              <img src={data.abpr} width={250} height={250} alt='Ashe Lynnuxx'/>

              <Text className="Titulosab" my={4}>
                Sobre mi
              </Text>
            </div>
            {data.Aboutme.map(bi0 => (
              <AboutCard key={bi0.bio} {...bi0} />
            ))}
          </div>
          <div className="FAQ">
            <div className=" flex items-center flex-col mx-auto justify-center">
              <Text className="Titulosab" my={4}>
                FAQ
              </Text>
            </div>
            <Accordion allowToggle>
              {data.Faq.map(faqq => (
                <FaqCard key={faqq.pre} {...faqq} />
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </Box>
  );
}
