import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Container from './components/layout/container/container';

import About from './pages/about';
import Home from './pages/home';
import Contact from './pages/contact';

import theme from './theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter>
        <Container>
          <Routes>
            <Route index path="/" element={<Home />} />
            <Route index path="/about" element={<About />} />
            <Route index path="/contact" element={<Contact />} />
          </Routes>
        </Container>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
